// api.js
import axios from 'axios';
import { clearLocalStorage, getLocalStorageItem, replace_chr_with } from './helpers';
// import { toast } from 'react-toastify';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Update axios instance configuration to ensure it handles file uploads properly
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Ensure axios doesn't try to serialize FormData objects
api.interceptors.request.use(config => {
    if (config.data instanceof FormData) {
        // For FormData, let the browser set the Content-Type header with boundary
        delete config.headers['Content-Type'];
    }
    return config;
}, error => Promise.reject(error));

// const handleApiError = (error) => {
//     if (error.message) {
//         console.error('API request failed:', error.message);
//         if (!window.location.href.toString().includes('login')) {
//             if (error) toast.error(error.message);
//         }

//     }
//     if (error.errors) {
//         const errs = Object.values(error.errors);
//         console.error(errs.join(', '));
//     }
//     throw error;
// };

export const downloadReport = async (api, fileType, reportType, range, device_id, device_name) => {
    try {
        const headers = { Authorization: `Bearer ${getToken()}` }; // Assuming getToken() retrieves the authorization token
        const response = await axios.get(`${API_BASE_URL}${api}`, {
            headers,
            responseType: 'blob', // Set response type to 'blob' for binary data
        });
        // Create a blob from the response for the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const filename = replace_chr_with(`${reportType}_report_${range}_${device_id}_${device_name ?? null}`, [' ', '-'], '_');
        link.setAttribute('download', `${filename}.${fileType}`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error:', error);
    }
};
const ifUnAuthorized = (error) => {
    if (error === 'Unauthenticated.') {
        clearLocalStorage();
        localStorage.setItem('last_page', window.location.href)
        window.location.href = '/login';
    }
}
export const get = async (url, params = {}, includeToken = true) => {
    try {
        const headers = includeToken ? { Authorization: `Bearer ${getToken()}` } : {};
        const response = await api.get(url, { params, headers });
        return response.data;
    } catch (error) {
        // handleApiError(error);
        ifUnAuthorized(error.response.data.message)

        throw error.response.data;

    }
};

export const post = async (url, data = {}, includeToken = true, includeContentType = false) => {
    try {
        let config = {
            headers: includeToken ? { Authorization: `Bearer ${getToken()}` } : {},
        };

        // When dealing with FormData, don't set Content-Type, axios will handle it correctly
        if (data instanceof FormData) {
            // Don't set content-type for FormData to ensure correct boundary is set
        } else if (includeContentType) {
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        const res = await api.post(url, data, config);
        return res.data;
    } catch (error) {
        // if (!window.location.href.toString().includes('login'))
        //     handleApiError(error.response.data);
        // else
        ifUnAuthorized(error.response.data.message)

        throw error.response.data;
    }
};

export const put = async (url, data = {}, includeToken = true, includeContentType = false) => {
    try {
        const headers = includeToken ? { Authorization: `Bearer ${getToken()}` } : {};

        // Include Content-Type header if specified
        if (includeContentType) {
            // headers['Content-Type'] = 'multipart/form-data';
        }

        const response = await api.put(url, data, { headers });
        return response.data;
    } catch (error) {
        // handleApiError(error);
        ifUnAuthorized(error.response.data.message)

        throw error.response.data;

    }
};

export const remove = async (url, includeToken = true) => {
    try {
        const headers = includeToken ? { Authorization: `Bearer ${getToken()}` } : {};
        const response = await api.delete(url, { headers });
        return response;
    } catch (error) {
        // handleApiError(error);
        ifUnAuthorized(error.response.data.message)

        throw error.response.data;

    }
};

export const downloadCsv = async (url, params = {}, filename = 'export.csv') => {
    try {
        const headers = { 
            Authorization: `Bearer ${getToken()}`,
            Accept: 'text/csv,application/csv'
        };
        
        // Build URL with query parameters
        let fullUrl = `${API_BASE_URL}${url}`;
        const queryParams = new URLSearchParams(params).toString();
        if (queryParams) {
            fullUrl += `?${queryParams}`;
        }
        
        const response = await axios.get(fullUrl, {
            headers,
            responseType: 'blob', // Set response type to 'blob' for binary data
        });
        
        // Check if we got CSV content
        const contentType = response.headers['content-type'];
        if (!contentType || (!contentType.includes('text/csv') && !contentType.includes('application/csv'))) {
            throw new Error(`Expected CSV but got ${contentType || 'unknown content type'}`);
        }
        
        // Get filename from Content-Disposition if available
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="(.+)"/);
            if (filenameMatch) {
                filename = filenameMatch[1];
            }
        }
        
        // Create a blob from the response for the download
        const url_object = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url_object;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        setTimeout(() => {
            window.URL.revokeObjectURL(url_object);
            document.body.removeChild(link);
        }, 100);
        
        return true;
    } catch (error) {
        console.error('CSV download error:', error);
        throw error;
    }
};

const getToken = () => {
    return getLocalStorageItem('token') || '';
};

export default api;
