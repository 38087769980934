import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getLocalStorageItem, getUserRole, isSuperAdmin, isSuperAdminUser } from './helpers/helpers';
import Loader from './components/Loader/Loader';
import CompanyPaymentReport from './components/Reports/CompanyPaymentReport';
import ModelNamesList from './components/ModelNames/ModelNamesList';
import TrackerModal from './components/Reports/TrackerModal';

export const GuardedRoute = ({ element, roles }) => {
    const navigate = useNavigate();
    const user = getLocalStorageItem('user');
    useEffect(() => {
        const checkAuthorization = () => {
            if (!user) {
                // Redirect to login if not authenticated
                // logout();
                // navigate('/login');
            } else if (roles && !roles.includes(getUserRole())) {
                // Redirect to unauthorized page if the user doesn't have the required role
                navigate('/unauthorized');
            }
        };

        checkAuthorization();
    }, [navigate, roles, user]);

    return <>{element}</>;
};
const Home = lazy(() => import('./components/Home'));
const CompanyList = lazy(() => import('./components/Companies/CompanyList'));
const CompanyDetail = lazy(() => import('./components/Companies/CompanyDetail'));
const CompanyForm = lazy(() => import('./components/Companies/CompanyForm'));
const CustomerList = lazy(() => import('./components/Customers/CustomerList'));
const CustomerForm = lazy(() => import('./components/Customers/CustomerForm'));
const CustomerLimits = lazy(() => import('./components/Customers/CustomerLimits'));
const CustomerDetail = lazy(() => import('./components/Customers/CustomerDetail'));
const CustomerAddDeviceForm = lazy(() => import('./components/Customers/CustomerAddDeviceForm'));
const CustomerDeviceList = lazy(() => import('./components/Customers/CustomerDeviceList'));
const InvoicesList = lazy(() => import('./components/Invoices/InvoicesList'));
const PaymentsList = lazy(() => import('./components/Invoices/PaymentsList'));
const RoleList = lazy(() => import('./components/Roles/RoleList'));
const RoleForm = lazy(() => import('./components/Roles/RoleForm'));
const UserList = lazy(() => import('./components/Users/UserList'));
const UserForm = lazy(() => import('./components/Users/UserForm'));
const ProfileForm = lazy(() => import('./components/Profile/ProfileForm'));
// const AgentMap = lazy(() => import('./components/GoogleMaps/Old/Old-AgentMap'));
const AgentMap = lazy(() => import('./components/GoogleMaps/AgentMap/AgentMap'));
const DevicesListSocket = lazy(() => import('./components/DebugCode/DevicesListSocket'));
const NotFound = lazy(() => import('./components/Errors/NotFound'));
const Login = lazy(() => import('./components/Login'));
const DevicesList = lazy(() => import('./components/Devices/DevicesList'));
const EventsList = lazy(() => import('./components/Events/EventsList'));
const DisposeOffList = lazy(() => import('./components/DisposeOffs/DisposeOffList'));
const SMTPSettings = lazy(() => import('./components/Settings/SMTPSettings'));
const ControlRoomEvents = lazy(() => import('./components/Settings/ControlRoomEvents'));
const MobileAppNotifications = lazy(() => import('./components/Settings/MobileAppNotifications'));
const InventoryList = lazy(() => import('./components/Inventory/InventoryList'));
const InventoryImei = lazy(() => import('./components/Inventory/ImportIMEIWithList'));
const DeviceDetail = lazy(() => import('./components/Customers/CustomerDeviceDetail'));
const DeviceModelList = lazy(() => import('./components/DeviceModels/DeviceModelList'));
const InventoryLocationList = lazy(() => import('./components/InventoryLocations/InventoryLocationList'));
const ActivitiesList = lazy(() => import('./components/Activities/ActivitiesList'));
const ReplayReport = lazy(() => import('./components/Reports/ReplayReport'));
const RouteReport = lazy(() => import('./components/Reports/RouteReport'));
const SummaryReport = lazy(() => import('./components/Reports/SummaryReport'));
const EventReport = lazy(() => import('./components/Reports/EventReport'));
const TripReport = lazy(() => import('./components/Reports/TripReport'));
const StopReport = lazy(() => import('./components/Reports/StopReport'));
const NRReport = lazy(() => import('./components/Reports/NRReport'));
const UnusualActivityReport = lazy(() => import('./components/Reports/UnusualActivityReport'));
const IdleReport = lazy(() => import('./components/Reports/IdleReport'));
const CompanyReport = lazy(() => import('./components/Reports/CompanyReport'));
const GeofencesPage = lazy(() => import('./components/Geofences/GeofencesPage'));
const UserTrails = lazy(() => import('./components/Users/UserTrails'));
const GeofenceReport = lazy(() => import('./components/Reports/GeofenceReport'));
const SpeedReport = lazy(() => import('./components/Reports/SpeedReport'));
const Announcements = lazy(() => import('./components/Announcements/Announcements'));
const GoogleApiKeys = lazy(() => import('./components/GoogleApiKeys/GoogleApiKeys'));
const BulkUploadCustomers = lazy(() => import('./components/Customers/BulkUploadCustomers'));
const BulkDownloadCustomers = lazy(() => import('./components/Customers/BulkDownloadCustomers'));


export const secure_routes = [
    { path: '/', element: <Navigate to="/dashboard" /> },
    { path: '/dashboard', element: <GuardedRoute element={<Suspense fallback={<Loader />} ><Home /></Suspense>} /> },
    { path: '/companies', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CompanyList /></Suspense>} /> },
    { path: '/company/create', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CompanyForm /></Suspense>} /> },
    { path: '/company/:companyId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CompanyForm /></Suspense>} /> },
    { path: '/company/detail/:companyId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CompanyDetail /></Suspense>} /> },
    { path: '/customers', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CustomerList /></Suspense>} /> },
    { path: '/customer/create', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CustomerLimits /></Suspense>} /> },
    { path: '/customer/:customerId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CustomerForm /></Suspense>} /> },
    { path: '/customer/detail/:customerId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CustomerDetail /></Suspense>} /> },
    { path: '/customer/add_device/:customerId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CustomerAddDeviceForm /></Suspense>} /> },
    { path: '/customer/device_list/:customerId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CustomerDeviceList /></Suspense>} /> },
    { path: '/invoices', element: <GuardedRoute element={<Suspense fallback={<Loader />}><InvoicesList type={isSuperAdmin() || isSuperAdminUser() ? 'company' : 'customer'} /></Suspense>} /> },
    { path: '/payments', element: <GuardedRoute element={<Suspense fallback={<Loader />}><PaymentsList type={isSuperAdmin() || isSuperAdminUser() ? 'company' : 'customer'} /></Suspense>} /> },
    { path: '/roles', element: <GuardedRoute element={<Suspense fallback={<Loader />}><RoleList /></Suspense>} /> },
    { path: '/role/create', element: <GuardedRoute element={<Suspense fallback={<Loader />}><RoleForm /></Suspense>} /> },
    { path: '/role/:roleId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><RoleForm /></Suspense>} /> },
    { path: '/users', element: <GuardedRoute element={<Suspense fallback={<Loader />}><UserList /></Suspense>} /> },
    { path: '/user/create', element: <GuardedRoute element={<Suspense fallback={<Loader />}><UserForm /></Suspense>} /> },
    { path: '/users/logs', element: <GuardedRoute element={<Suspense fallback={<Loader />}><UserTrails /></Suspense>} /> },
    { path: '/profile', element: <GuardedRoute element={<Suspense fallback={<Loader />}><ProfileForm /></Suspense>} /> },
    { path: '/map', element: <GuardedRoute element={<Suspense fallback={<Loader />}><AgentMap /></Suspense>} /> },
    // { path: '/new-map', element: <GuardedRoute element={<Suspense fallback={<Loader />}><ReAgentMap /></Suspense>} /> },
    { path: '/devices-list-socket', element: <GuardedRoute element={<Suspense fallback={<Loader />}><DevicesListSocket /></Suspense>} /> },
    { path: '/devices', element: <GuardedRoute element={<Suspense fallback={<Loader />}><DevicesList /></Suspense>} /> },
    { path: '/events', element: <GuardedRoute element={<Suspense fallback={<Loader />}><EventsList /></Suspense>} /> },
    { path: '/dispose-offs', element: <GuardedRoute element={<Suspense fallback={<Loader />}><DisposeOffList /></Suspense>} /> },
    { path: '/control-room-events', element: <GuardedRoute element={<Suspense fallback={<Loader />}><ControlRoomEvents /></Suspense>} /> },
    { path: '/mobile-app-notifications', element: <GuardedRoute element={<Suspense fallback={<Loader />}><MobileAppNotifications /></Suspense>} /> },
    { path: '/smtp-settings', element: <GuardedRoute element={<Suspense fallback={<Loader />}><SMTPSettings /></Suspense>} /> },
    { path: '/model-names', element: <GuardedRoute element={<Suspense fallback={<Loader />}><ModelNamesList /></Suspense>} /> },


    { path: '/reports/routes', element: <GuardedRoute element={<Suspense fallback={<Loader />}><RouteReport /></Suspense>} /> },
    { path: '/reports/replay', element: <GuardedRoute element={<Suspense fallback={<Loader />}><ReplayReport /></Suspense>} /> },
    { path: '/reports/summary', element: <GuardedRoute element={<Suspense fallback={<Loader />}><SummaryReport /></Suspense>} /> },
    { path: '/reports/event', element: <GuardedRoute element={<Suspense fallback={<Loader />}><EventReport /></Suspense>} /> },
    { path: '/reports/stops', element: <GuardedRoute element={<Suspense fallback={<Loader />}><StopReport /></Suspense>} /> },
    { path: '/reports/nr', element: <GuardedRoute element={<Suspense fallback={<Loader />}><NRReport /></Suspense>} /> },
    { path: '/reports/trips', element: <GuardedRoute element={<Suspense fallback={<Loader />}><TripReport /></Suspense>} /> },
    { path: '/reports/companies', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CompanyReport /></Suspense>} /> },
    { path: '/reports/payments', element: <GuardedRoute element={<Suspense fallback={<Loader />}><CompanyPaymentReport /></Suspense>} /> },
    { path: '/reports/unusual-activity', element: <GuardedRoute element={<Suspense fallback={<Loader />}><UnusualActivityReport /></Suspense>} /> },
    { path: '/reports/tracker-model', element: <GuardedRoute element={<Suspense fallback={<Loader />}><TrackerModal /></Suspense>} /> },
    { path: '/reports/idle', element: <GuardedRoute element={<Suspense fallback={<Loader />}><IdleReport /></Suspense>} /> },
    { path: '/reports/geofence', element: <GuardedRoute element={<Suspense fallback={<Loader />}><GeofenceReport /></Suspense>} /> },
    { path: '/reports/speed', element: <GuardedRoute element={<Suspense fallback={<Loader />}><SpeedReport /></Suspense>} /> },

    { path: '/activities', element: <GuardedRoute element={<Suspense fallback={<Loader />}><ActivitiesList /></Suspense>} /> },
    { path: '/device/detail/:deviceId', element: <GuardedRoute element={<Suspense fallback={<Loader />}><DeviceDetail /></Suspense>} /> },

    // Inventory
    { path: '/inventories', element: <GuardedRoute element={<Suspense fallback={<Loader />}><InventoryList /></Suspense>} /> },
    { path: '/inventory/imeis/:companyDeviceModelID', element: <GuardedRoute element={<Suspense fallback={<Loader />}><InventoryImei /></Suspense>} /> },
    { path: '/device-models', element: <GuardedRoute element={<Suspense fallback={<Loader />}><DeviceModelList /></Suspense>} /> },
    { path: '/inventory-locations', element: <GuardedRoute element={<Suspense fallback={<Loader />}><InventoryLocationList /></Suspense>} /> },
    // Add more guarded routes as needed

    //Geofences
    { path: '/geofences/:customerId?/:deviceId?', element: <GuardedRoute element={<Suspense fallback={<Loader />}><GeofencesPage /></Suspense>} /> },
    
    //Announcements
    { path: '/Announcements', element: <GuardedRoute element={<Suspense fallback={<Loader />}><Announcements /></Suspense>} /> },
    
    //Google Api
    { path: '/google-api-keys', element: <GuardedRoute element={<Suspense fallback={<Loader />}><GoogleApiKeys /></Suspense>} /> },
    
    //Bulk Upload Customers
    { path: '/company/:companyId/bulk-upload-customers', element: <GuardedRoute element={<Suspense fallback={<Loader />}><BulkUploadCustomers /></Suspense>} /> },

    { path: '/customer/bulk-upload-customers', element: <GuardedRoute element={<Suspense fallback={<Loader />}><BulkUploadCustomers /></Suspense>} /> },

    //Bulk Download Customers
    { path: '/company/:companyId/bulk-download-customers', element: <GuardedRoute element={<Suspense fallback={<Loader />}><BulkDownloadCustomers /></Suspense>} /> },

    { path: '/customer/bulk-download-customers', element: <GuardedRoute element={<Suspense fallback={<Loader />}><BulkDownloadCustomers /></Suspense>} /> },
];


export const public_routes = [
    { path: '/login', element: <Suspense fallback={<Loader />}><Login /></Suspense> },
    { path: '*', element: <Suspense fallback={<Loader />}><NotFound /></Suspense> },
];
